import React from 'react'

function Footer(props) {
  return (
    <div className={"bg-gray-100 bg-black p-6 text-center text-sm " + props.transition}>
  &copy; <span className="">R&Eacute;MI BLOT</span> 2024 &bull; <a className="link" href="/legal">Legal</a> 
  <br/>

</div>
  )
}

export default Footer
