import React,{useState, useRef, useEffect, useLayoutEffect, useContext} from 'react'
import { Link, useLoaderData, useParams,useLocation, useNavigate } from "react-router-dom"
import { ThemeContext } from '../context/ThemeContext';
import { useDarkMode } from '../customhooks/ThemeHook';
import {SplitText} from "@cyriacbr/react-split-text"
import TitleAnimated from './TitleAnimated'
import { BeakerIcon } from '@heroicons/react/24/solid'
import { Helmet } from 'react-helmet';
import YouTube from "react-youtube";
import gsap from 'gsap'
//import { SplitText } from "gsap/SplitText"
import './work_4.css'

var opts = {}

function WorkView(props) {

  function projectDataAfp(slug,data){
    // the code you're looking for
    var slug = slug;
    var obj = data;
    // iterate over each element in the array
    for (var i = 0; i < obj.length; i++){
      // look for the entry with a matching `code` value
      if (obj[i].slug == slug){
         // we found it
         return (obj[i]);
      }
    }
  }
const location = useLocation();
const navigate = useNavigate();
const theme = useContext(ThemeContext);
const { id } = useParams();


  let projects = (useLoaderData());
  //console.log(projects);


  var bg_image = null;
  var _coef = 10;
  var _coefDefault = 10;
  var _coefMobile = 30;
  var maxScale = 0.4
  var maxScaleDefault = 0.5;
  var maxScaleMobile = 1.2;
  const smoothSc = useRef();
  const growRef = useRef();
  //const featuredImgRef = useRef();

  const [projDataAfp, setProjDataAfp] = useState();
  const [scrollValue, setScrollValue] = useState(0);
  const [featuredImage, setFeaturedImage] = useState();
  const [posterImage, setPosterImage] = useState();
  const [featuredImageOne, setFeaturedImageOne] = useState();
  const [videoId, setVideoId] = useState(null);
  const [isPlayer,setIsPlayer] = useState(null);
  const [darkMode, setDarkMode] = useDarkMode(false);

  const  [pushOut,setPushOut] = useState();

  function outOfView(){
  setPushOut(false);
  }
  //const [direction, setDirection] = useState(null);
  //const [scrollSpeed, setScrollSpeed] = useState(0);
  //const [growIsIntersecting, setGrowIsIntersecting] = useState(false);



  let options = {
    rootMargin: "0px 0px 0px 0px",
    threshold: 0,
  };
  let callback = (entries, observer) => {
    entries.forEach((entry) => {
      // Each entry describes an intersection change for one observed
      // target element:
      //   entry.boundingClientRect
      //   entry.intersectionRatio
      //   entry.intersectionRect
      //   entry.isIntersecting
      //   entry.rootBounds
      //   entry.target
      //   entry.time

      //console.log(entry.isIntersecting);
      var elem = document.querySelector('#pictureGrow') ;
      //elem.style.transform =  'scale('+ 1 +  +')';


        if(entry.isIntersecting){
          //console.log('ok');
          //gsap.to('#pictureGrow',1,{ scale: 3,ease:'expo'})
          //growRef.current.style.position = 'fixed';


        }else{
          //console.log('not ok');
          //growRef.current.style.position = 'inherit';
        }
    });
  };
let observer = new IntersectionObserver(callback, options);

useEffect((props) => {
//gsap.to(featuredImgRef.current,.3,{ delay:0, height:'120vh',ease:'easeIn' });
});

useEffect((props)=> {
observer.observe(growRef.current);
},[growRef]);

useEffect((props)=> {
  if(projDataAfp){
    //console.log(projDataAfp.yt);
    if(projDataAfp.yt.length !== 0){
    setVideoId(projDataAfp.yt.resourceId.videoId);


    opts = {
      width: theme.state.width,
      height: theme.state.height,
      playerVars: {
        autoplay: 1,
        muted: 1,
        controls: 0,
        enablejsapi: 1,
        fs: 0,
        loop: 1,
        rel: 0,
        modestbranding: 1,
        showinfo: 0,
        playlist: projDataAfp.yt.resourceId.videoId ? projDataAfp.yt.resourceId.videoId : 'PLHeUFog1D_MuNeT_OZ0fVtsChMIW0KL-p',
        hd:1,
        //origin: 'frankariza.com'
      },
    };
  }
  }
},[projDataAfp,theme.state.width]);


useLayoutEffect(()=>{

  var _projDataAfp = projectDataAfp(id,projects.items);
  setProjDataAfp(_projDataAfp);
  setFeaturedImage(_projDataAfp.image_thumb_bg.url);
  setPosterImage(_projDataAfp.image_thumb.url);
  setFeaturedImageOne(_projDataAfp.image_thumb_1.url);


  //console.log('yt',_projDataAfp.yt != null ? 'YT ' + _projDataAfp.yt.resourceId.videoId : 'no YT');


if(darkMode === true){
  setDarkMode(!darkMode);
}

  },[location,projDataAfp]);

useLayoutEffect(()=>{

  _coef = theme.state.vertical === true ? _coefMobile : _coefDefault;
  maxScale = theme.state.vertical === true ? maxScaleMobile : maxScaleDefault;
  //bg_image = theme.state.vertical === true ? setFeaturedImage(projData.proj_img_mobile) : setFeaturedImage(projData.proj_img);
  bg_image = featuredImage;
  //console.log('maxscale', maxScale);

  var box = document.getElementById('pictureGrow');
  smoothSc.current.addEventListener( 'scroll', function(e) {

        // normalize scroll position as percentage
        //setScrollValue(e.currentTarget.scrollTop);
        var _scrollValue = e.currentTarget.scrollTop / e.currentTarget.scrollHeight;
        //console.log(_scrollValue);
        var _direction = e.currentTarget.scrollTop - scrollValue < 0 ? -1 : 1;
      //  setDirection(_direction);

        var _scale = gsap.utils.clamp(1, maxScale, _scrollValue * _coef ); // returns 100
        //var _scale = 1 + (e.currentTarget.scrollTop / e.currentTarget.scrollHeight); // returns 100


        //setScrollSpeed(_scale);

        var scrolled = _scale ,
        transformValue = 'scale('+scrolled+')';
        //gsap.to(box,.2,{delay:2, scale: _scale, ease:'expo' });


      window.requestAnimationFrame((e) =>{
    box.style.WebkitTransform = transformValue;
    box.style.MozTransform = transformValue;
    box.style.OTransform = transformValue;
    box.style.transform = transformValue;
      });



  }, false);

},[theme.state.vertical])





  return (


  <div style={{ background:'#fff', width:'100vw',height:'100%',display:'block',overflowY:'hidden',position:'fixed',top:0,left:0}} id="project" className={"center pushOut pushOut-" + darkMode}>
  <div style={{position:'relative'}} ref={smoothSc} id="project_prlx" className={"bg-dark " + props.transition}>
    <div style={{width:'100%',height:'100vh'}}>

      <div className="parallax">


        <div id="group4" className="parallax__group">
          <div className="parallax__layer parallax__layer--base">
            <div className="title">Base Layer 4.1
              <TitleAnimated
              level={1}
              id_t={'project_title'}
              classes={'proj-title text-center  pt-6  lg:pt-31 xl:pt-80 pb-4 text-title lg:text-title_lg xl:text-title_lg leading-6*2'}
              letterSpeed={0.02}
              scale={1.1}
              color="white"
              title={projDataAfp ? projDataAfp.name :'...'}
              afterTitle={true}
              />
          </div>
          </div>
          <div className="parallax__layer parallax__layer--back" style={{height:'100vh', backgroundImage: 'url(' + featuredImage + ')',backgroundPosition:'center', backgroundSize:'cover'}}>
            <div className="title">Background Layer 4.2</div>
          </div>
          <div className="parallax__layer parallax__layer--deep" style={{height:'300vh',background:'#333'}}>
            <div className="title text-white">Deep Background Layer 4.3</div>
          </div>
        </div>
        <div id="group5" className="parallax__group">
          <div className="parallax__layer parallax__layer--fore">
            <div className="title text-white">Foreground Layer 5.1</div>
          </div>
          <div className="parallax__layer parallax__layer--base" style={{height:'300vh',background:'#ededed'}}>
            <div className="title">Base Layer 5.2
              <img width="50" ref={growRef} className="-w-full" src={featuredImageOne} alt=""/>
          </div>
          </div>
        </div>

      </div>
</div>
<button className="closeProject1 link center text-center"
  onClick={()=> {
    setVideoId();
    setDarkMode(!darkMode);
    if(location.state ){
    //console.log(location.state.from);
    navigate("/");
  }
  }}
   style={{right:'5px',top:'120px',position:'fixed',zIndex:999999,color: darkMode && 'white', background: darkMode && '#db7093'}}>

   {!darkMode ?
     <>
<svg
     style={{transform:'scale(1.0)'}}
     fill="none"
     viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">

       <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="24" height="24"></rect> <path d="M7 17L16.8995 7.10051" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M7 7.00001L16.8995 16.8995" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>

     </>
   : ''}

 </button>









</div>
</div>
  )
}

export default WorkView
