import React,{ useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'


function Navbar() {
  const navRef = useRef();
  const  [navCondition,setNavCondition] = useState(false);

  function toggleNav(condition){
    navCondition === true ? setNavCondition(false) : setNavCondition(true);
  }

  return (

    <nav className={"flex px-4 border-b-gray-900 items-center relative open-" + navCondition}>
        <div style={{zIndex:1000}} className="flex text-lg font-bold md:py-0 py-4 z-600">
          <NavLink aria-label="Click to navigate to the homepage" to="/">  <img className="logoIcon" src="/img/af-films-logo.svg" alt=""/></NavLink>
        </div>
        <ul ref={navRef} className={ 'nav nav-' + navCondition +" md:px-2 ml-auto md:flex md:space-x-2 absolute md:relative top-full left-0 right-0 z-200"}>
            <li>
              <NavLink onClick={() => setNavCondition(false) } className="flex md:inline-flex p-4 items-center hover:bg-gray-800" to={"/"}>


                    <span className="text-black uppercase glowTitle">&Iacute;nicio</span>
                    </NavLink>
            </li>


            <li>
              <NavLink onClick={() => setNavCondition(false) } className="flex md:inline-flex p-4 items-center hover:bg-gray-800" to={"/tv"}>


                    <span className="text-black uppercase glowTitle">TV</span>
                    </NavLink>
            </li>


            <li>
              <NavLink onClick={() => setNavCondition(false) } className="flex md:inline-flex p-4 items-center hover:bg-gray-800 nav" to={"/cine"}>


                    <span className="text-black uppercase glowTitle">Cine</span>
              </NavLink>
            </li>


            <li>
              <NavLink onClick={() => setNavCondition(false) } className="flex md:inline-flex p-4 items-center hover:bg-gray-800 nav" to={"/documentales"}>

                    <span className="text-black uppercase glowTitle">Documentales</span>
                    </NavLink>
            </li>


            <li>
              <NavLink onClick={() => setNavCondition(false) } className="flex md:inline-flex p-4 items-center hover:bg-gray-800 nav" to={"/noticias"}>

                    <span className="text-black uppercase glowTitle">Noticias</span>
                    </NavLink>
            </li>




            <li>
              <NavLink onClick={() => setNavCondition(false) } className="flex md:inline-flex p-4 items-center hover:bg-gray-800" to={"/contacto"}>

                    <span className="text-black uppercase glowTitle">Contacto</span>
                    </NavLink>
            </li>

        </ul>
        <div style={{zIndex:1000}} className="ml-auto md:hidden text-black cursor-pointer">
          <a onClick={() => toggleNav() } href="#nav">
            <svg className={"w-5 h-5 fill-current navBtn open-" + navCondition} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
          </a>
      </div>
    </nav>

  )
}

export default Navbar
