//https://github.com/vandit-bera/React-Routing
import React,{ Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import DOMPurify from "dompurify";
import './index.css';
import ErrorPage from './component/ErrorPage';
import Navbar from "./component/Navbar";
import App from './App';
import Legal from "./component/Legal";
import Blog from "./component/Blog";
import Contact from "./component/Contact";
//import Home from "./component/Home";
import Services from "./component/Services";
import Team from "./component/Team";
//import WorkView from "./component/WorkView";
import WorkView_1 from "./component/WorkView_1";
import WorkView_parallax from "./component/WorkView_parallax";
//import WorkDoc from "./component/WorkDoc";
//import WorkCine from "./component/WorkCine";
//import WorkTv from "./component/WorkTv";
//import News from "./component/News";
import NewsView from "./component/NewsView";
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import ThemeProvider from './context/ThemeProvider';

const htmlLoader = '<div style="z-index:9999;width:100%;height:auto;margin-top:50vh;display:block;position:fixed;left:0;top:0;text-align:center"><img style="display:flex;margin:0 auto;margin-top:-25px;vertical-align:center" src="/img/puff.svg" width="50" alt=""/></div>';
const staticLoader = DOMPurify.sanitize(htmlLoader);
const Home = React.lazy(() => import('./component/Home'));
const WorkCine = React.lazy(() => import('./component/WorkCine'));
const WorkTv = React.lazy(() => import('./component/WorkTv'));
const WorkDoc = React.lazy(() => import('./component/WorkDoc'));
const News = React.lazy(() => import('./component/News'));

const fetchProjectsHome = async () => {
const response = await fetch('https://discover.frankariza.com/app/v1/video-data-af-all');
return await response.json();
};
const fetchProjectsCine = async () => {
const response = await fetch('https://discover.frankariza.com/app/v1/video-data-af-cine');
return await response.json();
};
const fetchProjectsTv = async () => {
const response = await fetch('https://discover.frankariza.com/app/v1/video-data-af-tv');
return await response.json();
};
const fetchProjectsDoc = async () => {
const response = await fetch('https://discover.frankariza.com/app/v1/video-data-af-doc');
return await response.json();
};
const fetchNews = async () => {
const response = await fetch('https://discover.frankariza.com/api/v1/blog');
return await response.json();
};


const router = createBrowserRouter([
  {
    element: <App />,
     errorElement: <ErrorPage transition={`${'fadeIn'}`}/>,
    children: [
  {
    path: "/",
    element: (
    <Suspense fallback={<div dangerouslySetInnerHTML={{ __html: staticLoader }} />}>
    <Home transition={`${'fadeIn'}`} />
     </Suspense>
   ),
    loader: fetchProjectsHome,
  },
  {
    path: "/cine",
    element: (
      <Suspense fallback={<div dangerouslySetInnerHTML={{ __html: staticLoader }} />}>
        <WorkCine title={'CINE'} transition={`${'fadeIn'}`} />
     </Suspense>
   ),
    loader: fetchProjectsCine,
    children: [
      {
        path: ":id",
        element: <WorkView_parallax transition={`${'fadeIn'}`} />,
        loader: fetchProjectsCine,
        errorElement: <ErrorPage transition={`${'fadeIn'}`} />,
      },
    ],
  },
  {
    path: "/documentales",
    element: (
      <Suspense fallback={<div dangerouslySetInnerHTML={{ __html: staticLoader }} />}>
        <WorkDoc title={'documentales'} transition={`${'fadeIn'}`} />
      </Suspense>
    ),
    loader: fetchProjectsDoc,
    children: [
      {
        path: ":id",
        element: <WorkView_1 transition={`${'fadeIn'}`} />,
        loader: fetchProjectsDoc,
        errorElement: <ErrorPage transition={`${'fadeIn'}`} />,
      },
    ],
  },
  {
    path: "/tv",
    element: (
      <Suspense fallback={<div dangerouslySetInnerHTML={{ __html: staticLoader }} />}>
        <WorkTv title={'TV'} transition={`${'fadeIn'}`} />
        {staticLoader}
      </Suspense>
    ),
    loader: fetchProjectsTv,
    children: [
      {
        path: ":id",
        element: <WorkView_1 transition={`${'fadeIn'}`} />,
        loader: fetchProjectsTv,
        errorElement: <ErrorPage transition={`${'fadeIn'}`} />,
      },
    ],
  },
  {
    path: "/noticias",
    element: (
      <Suspense fallback={<div dangerouslySetInnerHTML={{ __html: staticLoader }} />}>
        <News title={'NOTICIAS'} transition={`${'fadeIn'}`} />
      </Suspense>
    ),
    loader: fetchNews,
    children: [
      {
        path: ":id",
        element: <NewsView transition={`${'fadeIn'}`} />,
        loader: fetchNews,
        errorElement: <ErrorPage transition={`${'fadeIn'}`} />,
      },
    ],
  },
  {
    path: "/servicios",
    element: <Services transition={`${'fadeIn'}`} />,
  },
  {
    path: "/contacto",
    element: <Contact transition={`${'fadeIn'}`} />,
  },
  {
    path: "/legal",
    element: <Legal transition={`${'fadeIn'}`} />,
  },
]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

  <ThemeProvider>

    <RouterProvider router={router} future={{ v7_startTransition: true }}>
<Navbar />
    </RouterProvider>
    </ThemeProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
